<template>
<div class="sendPhoneCode">
    <div class="text" @click="sendCode()">{{sendText}}</div>
</div>
</template>

<script>
import { post } from '@/axios/index'
import {Toast} from "vant";

export default {
  name: "sendPhoneCode",
  components: {},
  props: {
    url: {
      type: String,
      default: ""
    },
    verify: {
      // 图形验证码
      type: Number,
      default: null
    },
    mobile: {
      // 发送手机号
      type: Number,
      default: null
    },
  },
  data() {
    return {
      sendText: "发送验证码",
      internal: null,
      timeOut: 60,
    }
  },
  mounted() {
  },
  methods: {
    sendCode() {
      if (this.timeOut < 60) return;
      this.$emit("sendSmsCode")
    },
    setInternal(){
      Toast("发送成功")
      this.internal = setInterval(()=> {
        this.timeOut--;
        this.sendText = "重新发送("+this.timeOut+')'
        if (this.timeOut === 0) {
          this.timeOut = 60;
          this.sendText = '发送验证码';
          clearInterval(this.internal)
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.sendPhoneCode {
  .text {
    flex-shrink: 0;
    color: red;
    font-size: 14px;
  }
}
</style>