 <template>
    <div class="withdrawPassword page">
      <headers title="修改提现密码"></headers>
      <div class="scroll">
        <ul class="list">
          <li class="flexCenter" v-for="(item,index) in form_list" :key="index">
            <div class="label">{{item.label}}</div>
            <div class="right">
              <p v-if="index === 0" class="phone">{{item.value}}</p>
              <div class="flexCenter" v-else>
                <van-field
                    v-model="item.value"
                    label=""
                    :placeholder="'请输入'+item.label"
                    :type="item.type"
                />
                <div v-if="item.key == 'code'" class="sendCode">
                  <sendPhoneCode ref="sendPhoneCodeRef" @sendSmsCode="sendSmsCode"></sendPhoneCode>
                </div>
              </div>

            </div>
          </li>
        </ul>
        <div style="margin: 25px 15px;">
          <van-button round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="submit()">提交</van-button>
        </div>
      </div>
    </div>
  </template>

  <script>
    import headers from "@/components/headers.vue";
    import sendPhoneCode from "@/components/sendPhoneCode.vue";

    import {Toast} from "vant";

    export default {
      name: "withdrawPassword",
      components: {
        headers,
        sendPhoneCode
      },
      data() {
        return {
          form_list: [
            {
              label: '+86',
              key: 'account',
              value: '',
            },
            {
              label: '验证码',
              key: 'code',
              type: 'text',
              value: ''
            },
            {
              label: '新密码',
              type: 'password',
              key: 'password1',
              value: ''
            },
          ],
        }
      },
      mounted() {
        this.getInfo();
      },
      methods: {
        getInfo(){
          $api.password({
            token: this.$store.getters["getToken"]
          }).then((res) => {
            this.form_list[0].value = res.data.account;
          })
        },
        sendSmsCode(){
            $api.password1_send_sms_code({
              token: this.$store.getters["getToken"]
            }).then(()=> {
                 this.$refs.sendPhoneCodeRef[0].setInternal()
             })
        },
        submit(){
          let form = {}
          for (var i = 0; i < this.form_list.length; i++) {
            if (this.form_list[i].value === '') {
              Toast({
                message: this.form_list[i].label + '不能为空',
                duration: 1500
              });
              return false;
            }
            form[this.form_list[i].key] = this.form_list[i].value
          }
          $api.set_password1({
            ...form,
            token: this.$store.getters["getToken"],
          }).then(res => {
            Toast('修改成功')
            setTimeout(()=>{
              this.$router.back()
            },1000)
          })
        }
      }
    }
  </script>

  <style lang="scss">
    .withdrawPassword {
      .scroll {
        .list {
          li {
            padding: 10px 16px;
            height: 50px;
            .label {
              font-size: 16px;
              position: relative;
              width: 80px;
              text-align: left;
              border-right: 1px solid #e5e5e5;
              margin-right: 15px;
              height: 100%;
              display: flex;
              align-items: center;
              flex-shrink: 0;
            }
            .phone {
              font-size: 15px;
              padding-left: 15px;
            }
            .sendCode {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  </style>